import { getModuleValue } from "api/module";

import { useQuery } from "react-query";
import tuple from "utils/types/tuple";
import qs from "query-string";

function useAdhocDetail(enabled?: boolean) {
  const url = qs.parse(window.location.search);

  return useQuery(
    tuple(["getModuleValue", "ad_hoc_service", url.p as string]),
    getModuleValue,
    { enabled }
  );
}

export default useAdhocDetail;
