import clsx from "clsx";
import React, { ReactNode } from "react";
import useLang from "utils/hooks/useLang";

type Props = {
  title: string;
  zhTitle: string;
  prefix?: ReactNode;
  size?: "xs" | "sm" | "md";
  bar?: boolean;
};

function Title({ title, zhTitle, size = "md", prefix, bar }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lang, _isLang, isBi] = useLang();

  if (!isBi) {
    return (
      <div
        className={clsx([
          "flex items-center text-[#01328A] font-bold py-2 lg-title",
          size === "sm" && "text-[16px]",
          size === "md" && "text-[18px]",
          size === "xs" && "text-xs",
        ])}
      >
        {bar && (
          <div
            className="bg-[#E61E25] inline-block w-1 mr-2 h-[1em] relative"
            style={{ top: 2, minWidth: 4 }}
          />
        )}
        <h4 className="leading-tight m-0 text-left">
          {lang === "en" ? title : zhTitle}
        </h4>
        {prefix}
      </div>
    );
  }

  return (
    <div
      className={clsx([
        "flex items-center text-[#01328A] font-bold py-2",
        size === "sm" && "text-[16px]",
        size === "md" && "text-[18px]",
        size === "xs" && "text-xs",
      ])}
    >
      {bar && (
        <div
          className="bg-[#E61E25] inline-block w-1 mr-2 relative"
          style={{ top: 2, minWidth: 4 }}
        />
      )}
      <h4 className="leading-tight m-0 text-left">{title}</h4>
      <h5 className="ml-1 leading-none text-[80%] m-0">{zhTitle}</h5>
      {prefix}
    </div>
  );
}

export default Title;
