import { stringify } from "query-string";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import _ from "lodash";

const useRenderTable = (prefix = "lt_") => {
  const [tableStart, setTableStart] = useState<number>();
  const [dummyBlock, setDummyBlock] = useState<number>();
  const [overflowed, setOverflowed] = useState(false);

  const history = useHistory();
  useEffect(() => {
    if (tableStart && dummyBlock) {
      return;
    }
    const str = qs.parse(history.location.search);
    const strArr = String(str[`${prefix}${String(str.lastStart)}`]).split("-");
    if (strArr[0]) {
      setTableStart(Number(strArr[0]));
    }
    if (strArr[1]) {
      setDummyBlock(Number(strArr[1]));
    } else {
      // find the nearest dummy block
      _.range(Number(str.lastStart))
        .reverse()
        .some((s) => {
          const arr = String(str[`${prefix}${String(s)}`]).split("-");
          if (arr[1]) {
            setDummyBlock(Number(arr[1]));
            return true;
          }
          return false;
        });
    }
  }, [dummyBlock, history.location.search, prefix, tableStart]);

  const updateOverflowIndex = useCallback(
    (k: number) => {
      const currentSearch = qs.parse(window.location.search);
      history.replace({
        ...history.location,
        search: stringify({
          ...currentSearch,
          [`lt_${String(currentSearch.start ?? 0)}`]: `${k}-${
            dummyBlock ? dummyBlock + 1 : 1
          }`,
        }),
      });
      setOverflowed(true);
    },
    [dummyBlock, history]
  );

  const renderTable = useCallback(
    (table: (props: any) => ReactNode) => {
      return [
        ...(dummyBlock
          ? _.range(dummyBlock).map((s) => (
              <div key={s} title={`dummy-${s + 1}`} />
            ))
          : []),
        table({
          id: dummyBlock,
          start: tableStart,
          onOverflow: updateOverflowIndex,
          useOffsetHeight: true,
          sized: true,
        }),
        ...(overflowed
          ? [<div style={{ height: 15300 }} title="overflow" />]
          : []),
      ];
    },
    [dummyBlock, overflowed, tableStart, updateOverflowIndex]
  );

  return renderTable;
};

export default useRenderTable;
