import { getModuleFileInfo } from "api/module-file";
import { isPlainObject } from "lodash-es";
import React from "react";
import { useQuery } from "react-query";
import useLang from "utils/hooks/useLang";
import { safeJSONParse } from "utils/transform/json";
import tuple from "utils/types/tuple";

import Image from ".";

interface Props {
  id: string;
  fileId: string;
  module: string;
  showFileInfo?: boolean;
}

export const getSafeFileAttributes = (value: any): Record<string, string> => {
  const parsedValue = safeJSONParse(value, {});
  return isPlainObject(parsedValue) ? parsedValue : {};
};

const dimension = 800;
const displayDimension = 400;

function ImageUid({ id, fileId, module, showFileInfo }: Props) {
  const [langKey] = useLang();
  const { data: fileData } = useQuery(
    tuple(["getModuleFileInfo", { id, fileId, module }]),
    getModuleFileInfo,
    {
      staleTime: 900000,
      enabled: !!(showFileInfo && id && fileId && module),
    }
  );

  const title = getSafeFileAttributes(fileData?.title)[langKey];
  const description = getSafeFileAttributes(fileData?.description)[langKey];

  return (
    <>
      <Image
        width={dimension}
        height={dimension}
        widthDisplay={displayDimension}
        heightDisplay={displayDimension}
        alt={`cms-preview-${id}`}
        image={{
          width: dimension,
          height: dimension,
          id: id,
          module_name: module,
          uid: fileId,
        }}
        key={fileId}
        type={undefined}
        uuid
      />
      {showFileInfo && (title || description) && (
        <div className="text-xs" style={{ paddingTop: 6 }}>
          {title && (
            <div>
              <b>{title}</b>
            </div>
          )}
          {description && <div>{description}</div>}
        </div>
      )}
    </>
  );
}

export default ImageUid;
