import _ from 'lodash';

export function prettifyLetters(string: string) {
  if (!_.isString(string)) return '';
  if (string.length === 0) return '';
  if (string.length === 1) return string.toUpperCase();
  const str = string.split(/-|_/);
  return str
    .map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join(' ');
}

export function objectToPrettifyString(object) {
  if (_.isString(object)) {
    return object;
  }
  let transformedObject = object;
  if (_.isArray(object)) {
    transformedObject = { ...object };
  }
  let str = '';
  const keys = _.keys(transformedObject);
  keys.forEach((key, index) => {
    // Key
    str += `${key}: `;
    // Value
    const value = object[key];
    if (_.isPlainObject(value)) {
      str += objectToPrettifyString(value);
    } else if (_.isString(value)) {
      str += value;
    } else {
      // TODO handle array
      str += JSON.stringify(value);
    }
    if (index < keys.length - 1) str += ', ';
  });
  return str;
}
