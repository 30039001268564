import { useCallback, useEffect, useState } from "react";
import { encryptToken } from "utils/query/rsa";
import { getToken } from "api/_token";

var staticTimer: number | undefined;
var staticEncryptedToken: string | null = null;
var staticLastUpdate = 0;

var tokenTimeout = 3600000;

function useEncryptedToken() {
  const [encryptedToken, setEncryptedToken] = useState<string | null>(
    staticEncryptedToken
  );

  const token = getToken();

  const generateToken = useCallback((t: string) => {
    const timeNow = new Date().getTime();
    if (staticLastUpdate < timeNow - tokenTimeout) {
      // Generate new encrypted token
      staticEncryptedToken = encryptToken(t, tokenTimeout);
      staticLastUpdate = timeNow;
    }
    if (staticEncryptedToken) setEncryptedToken(staticEncryptedToken);
  }, []);

  useEffect(() => {
    if (!token) return;
    generateToken(token);
    staticTimer = window.setInterval(() => {
      generateToken(token);
    }, tokenTimeout);
    return () => {
      if (staticTimer) {
        clearInterval(staticTimer);
      }
      staticTimer = undefined;
    };
  }, [generateToken, token]);

  return encryptedToken;
}

export default useEncryptedToken;
