import React from "react";

import Footer from "components/Footer";
import Header from "components/Header";
import PageSizeContent from "components/PageSizeContent";
import Scaffold from "components/Scaffold";
import TextTuple from "components/TextTuple";
import useTaskDetail from "containers/useTaskDetail";
import useModuleDetail from "containers/useModuleDetail";
import Divider from "components/Divider";
import LangText from "components/LangText";
import { dateToString } from "utils/transform/date";
import ImageServer from "components/Image/ImageServer";
import Title from "components/Title";
import TextareaDisplay from "components/TextareaDisplay";
import {
  captionTranslate,
  getBothLangContent,
} from "utils/transform/multilang";
import useSummaryProduct from "./useSummaryProduct";
import useModuleListing from "containers/useModuleListing";
import _ from "lodash";
import ProductLegend from "components/Table/ProductLegend";

type Props = {};

const SummaryPage = (props: Props) => {
  const { data } = useTaskDetail();
  const { data: clientData } = useModuleDetail("client", data?.value?.client);
  const { data: supervisorData } = useModuleDetail(
    "cms_user",
    data?.value?.supervisor
  );
  const { data: techData } = useModuleDetail(
    "technician",
    data?.value?.assign_technicians?.[0]
  );
  const { data: facilityData } = useModuleDetail(
    "facility",
    data?.value?.facility
  );
  const { data: projectData } = useModuleDetail(
    "project",
    data?.value?.project
  );

  const contactIds =
    !_.isEmpty(data?.value?.report_contact_ids) &&
    _.isArray(data?.value?.report_contact_ids)
      ? data?.value?.report_contact_ids
      : [facilityData?.value?.contacts?.[0]].filter((s) => !!s);
  const { data: contactDataListRes } = useModuleListing("contact", contactIds);
  const contactDataList = contactDataListRes?.value || [];

  const renderSummaryProduct = useSummaryProduct();

  const title = `${
    data?.value?.type === "maintenance" ? "MAINTENANCE" : "SERVICE"
  } REPORT SUMMARY`;
  const zhTitle = `${
    data?.value?.type === "maintenance" ? "維修保養" : "服務"
  }總結報告`;

  return (
    <PageSizeContent size="a4" className="page_summary">
      <Scaffold
        header={
          <Header
            title={title}
            zhTitle={zhTitle}
            subTitle={undefined}
            zhSubTitle={undefined}
          />
        }
        footer={<Footer />}
      >
        <div>
          <TextTuple
            title="Client Company"
            zhTitle="公司"
            value={<LangText>{clientData?.value?.company_name}</LangText>}
            className="pb-4"
          />
          <div className="grid grid-cols-2 gap-x-8 gap-y-4">
            <TextTuple
              title="Attn"
              zhTitle="致"
              value={contactDataList.map((s) => s.name).join(" / ")}
            />
            <TextTuple
              title="P.O No."
              zhTitle="訂單編號"
              value={projectData?.value?.po_no}
            />
            <TextTuple
              title="Tel"
              zhTitle="電話"
              value={contactDataList
                .map(
                  (s) =>
                    `${s.phone_region_code ? `+${s.phone_region_code} ` : ""}${
                      s.phone || ""
                    }`
                )
                .join(" / ")}
            />
            <TextTuple
              title="Project No."
              zhTitle="項目編號"
              value={projectData?.value?.project_no}
            />
            <TextTuple
              title="Email"
              zhTitle="電郵"
              value={contactDataList.map((s) => s.email).join(" / ")}
            />
            <TextTuple
              title="Reference Number."
              zhTitle="參考編號"
              value={projectData?.value?.ref_no}
            />
          </div>
          <Divider />
          <TextTuple
            title="Project"
            zhTitle="項目名稱"
            value={<LangText>{data?.value?.name}</LangText>}
            className="pb-4"
          />
          <TextTuple
            title="Project Address"
            zhTitle="項目地址"
            value={[
              facilityData?.value?.address?.address_line_1,
              facilityData?.value?.address?.address_line_2,
              facilityData?.value?.address?.address_line_3,
            ]
              .filter((s) => !!s)
              .map((s) => captionTranslate(s))
              .join(", ")}
            className="pb-4"
          />
          <TextTuple
            title="Service Location"
            zhTitle="工作地址"
            value={captionTranslate(facilityData?.value?.name)}
            className="pb-6"
          />
          <div className="flex justify-between mb-1" key="title">
            <Title title="MAINTENANCE ITEMS" zhTitle="保養項目" bar size="md" />
            <ProductLegend className="pl-2" />
          </div>
        </div>
        {/* MAINTENANCE ITEMS */}
        {renderSummaryProduct.flatMap((s) => s)}
        {/* Report Summary*/}
        <div className="pt-5">
          <Title title="Report Summary" zhTitle="報告總結" size="sm" />
          <TextareaDisplay html={getBothLangContent(data?.value?.summary)} />
        </div>
        <div className="pt-5">
          <Title title="Recommendation" zhTitle="建議" size="sm" />
          <TextareaDisplay
            html={getBothLangContent(data?.value?.recommendation)}
          />
        </div>
        {/* Bottom Part, signature, responsible people, group in 1 div, to better page split */}
        <div>
          <div className="grid grid-cols-2 gap-4 pt-8">
            <TextTuple
              title="Maintenance Technician"
              zhTitle="保養技術員"
              value={techData?.value?.name}
            />
            <TextTuple
              title="Maintenance Date &amp; Time"
              zhTitle="保養日期和時間"
              value={dateToString(
                data?.value?.maintenance_report_datetime ||
                  data?.value?.maintenance_datetime,
                "datetime"
              )}
            />
            <TextTuple
              title="Inspector / Engineer"
              zhTitle="檢查人員"
              value={supervisorData?.value?.name}
            />
            <TextTuple
              title="Inspection Date &amp; Time"
              zhTitle="檢查日期和時間"
              value={dateToString(data?.value?.inspection_datetime, "datetime")}
            />
          </div>
          {data?.value?.signature && (
            <TextTuple
              title="Client Signature"
              zhTitle="客戶代表簽名"
              value=""
              className="pt-4 !items-start"
            >
              <ImageServer
                displayHeight={87}
                displayWidth={174}
                uuid={data.value.signature}
                module="task"
                id={data?.value?.id}
              />
            </TextTuple>
          )}
        </div>
      </Scaffold>
    </PageSizeContent>
  );
};

export default SummaryPage;
