import React, { useEffect, useState } from "react";

type Props = {};

const CoverResizer = (props: Props) => {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", (event) => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    });
  }, []);

  if (height < 1200 || width < 1200) {
    return (
      <div className="fixed top-0 left-0 bottom-0 right-0 bg-[rgba(0,0,0,0.85)] flex items-center justify-center p-5">
        <div className="text-center text-white leading-loose">
          Your window is too small, please zoom out or resize your window.
          <br />
          你的視窗太小，請縮小或調整您的窗口大小。
        </div>
      </div>
    );
  }
  return <></>;
};

export default CoverResizer;
