import { getModuleListing } from "api/module";

import { useQuery } from "react-query";
import tuple from "utils/types/tuple";

const useReplies = (taskId: string | undefined) => {
  return useQuery(
    tuple([
      "getModuleListing",
      "poly_data",
      {
        limit: 999,
        sortBy: "id",
        sortDirection: "desc" as const,
        mode: "all" as const,
        search: [
          {
            field: "task",
            operator: "==",
            value: taskId,
          },
        ],
      },
      1,
    ]),
    getModuleListing,
    {
      enabled: !!taskId,
    }
  );
};

export default useReplies;
