import { getModuleListing } from "api/module";

import { useQuery } from "react-query";
import { ModuleList } from "types/module";
import tuple from "utils/types/tuple";
import { isEmpty } from "lodash-es";
import _ from "lodash";

type Props = [ModuleList, string[] | number[] | undefined | true, boolean?];

function useModuleListing(...args: Props) {
  return useQuery(
    tuple([
      "getModuleListing",
      args[0],
      {
        limit: _.isArray(args[1]) ? args[1].length : 1,
        sortBy: "id",
        sortDirection: "desc" as const,
        mode: "all" as const,
        search: [
          {
            field: "id",
            operator: "in",
            value: args[1],
          },
        ],
      },
      1,
    ]),
    getModuleListing,
    {
      enabled:
        !!args[1] &&
        (!isEmpty(args[1]) || args[1] === true) &&
        args[2] !== false,
    }
  );
}

export default useModuleListing;
