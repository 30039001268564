const routes = {
  overview: () => "/",
  cover: () => "/cover",
  appendix: () => "/appendix",
  summary: () => "/summary",
  product: (productId: string) =>
    `/product${productId ? `?e=${productId}` : ""}`,
  coverAdhoc: () => "/cover-adhoc",
  appendixAdhoc: () => "/appendix-adhoc",
  summaryAdhoc: () => "/summary-adhoc",
  productAdhoc: () => "/product-adhoc",
};

export default routes;
