export const safeJSONParse = (
  jsonStr: string | undefined,
  defaultValue?: any,
) => {
  try {
    if (!jsonStr) return defaultValue;
    return JSON.parse(jsonStr);
  } catch (e) {
    console.warn(e);
    return defaultValue;
  }
};
