import _ from "lodash";

import { ModuleDefinition } from "types/module";

import { callAPIHandler } from "./_base";

export interface FormError {
  errorCode: string;
  error: boolean;
  errorMessage: { [x: string]: string[] };
}

type getModuleDefinitionQueryKey = [string, string];

export const getModuleDefinition = ({
  queryKey,
}: {
  queryKey: getModuleDefinitionQueryKey;
}): Promise<ModuleDefinition> => {
  const [, module] = queryKey;
  return callAPIHandler("get", `/api/admin/${module}/definition`, {}, true, {
    cache: true,
  });
};

export interface getModuleListingParam {
  limit: number;
  sortBy?: string;
  sortDirection?: "asc" | "desc";
  search?: {
    field: string;
    operator: string;
    value: any;
  }[];
  mode?: "export" | "deleted" | "all";
  sequence?: string;
}

type getModuleListingQueryKey = [string, string, getModuleListingParam, number];

export interface getModuleListingResponse {
  count: number;
  value: { [x: string]: any }[];
}

export const getModuleListing = ({
  queryKey,
}: {
  queryKey: getModuleListingQueryKey;
}): Promise<getModuleListingResponse> => {
  const [
    ,
    module,
    { limit, sortBy, sortDirection, search, mode, sequence },
    page,
  ] = queryKey;
  return callAPIHandler(
    "get",
    `/api/admin/${module}/listing`,
    {
      page,
      limit,
      order: sortBy,
      direction: sortDirection,
      search,
      sequence,
      ...(mode === "export" ? { export: "1" } : {}),
      ...(mode === "deleted" ? { deleted: "1" } : {}),
      ...(mode === "all" ? { listing: "0" } : {}),
    },
    true
  );
};

export interface ModuleValueResponse {
  id: string;
  value: {
    sts: string;
    created_at: number;
    updated_at: number;
    [x: string]: any;
  };
}

export const getModuleValue = ({
  queryKey,
}: {
  queryKey: [string, string, string, any?]; // key, module, id
}): Promise<ModuleValueResponse> => {
  const [, module, id, option] = queryKey;
  return callAPIHandler(
    "get",
    `/api/admin/${module}/edit/${id}`,
    {},
    true,
    option
  );
};

export const getModuleDefaultValue = async ({
  queryKey,
}: {
  queryKey: [string, string]; // key, module
}) => {
  try {
    const [, module] = queryKey;
    const res = await callAPIHandler(
      "get",
      `/api/admin/${module}/edit-default`,
      {},
      true,
      {
        cache: true,
      }
    );
    return (_.isPlainObject(res) ? res : {}) as ModuleValueResponse; // Default to plain object on other type
  } catch (e) {
    return {};
  }
};

interface requestModuleCreateIdResponse {
  id: string;
  value?: {
    [x: string]: any;
  };
}

// Update Value
export const requestModuleCreateId = ({
  module,
}: {
  module: string;
}): Promise<requestModuleCreateIdResponse> => {
  return callAPIHandler("post", `/api/admin/${module}/create`, {}, true);
};

interface StoreModuleValueParams {
  data: {
    save_type:
      | "save_as_staging"
      | "save_as_published"
      | "save_as_draft"
      | undefined;
    [x: string]: any;
  };
  module: string;
  id: string;
}

export const storeModuleValue = ({
  data,
  module,
  id,
}: StoreModuleValueParams) =>
  callAPIHandler("post", `/api/admin/${module}/store/${id}`, data, true);

interface PublishModuleParams {
  module: string;
  id: string;
}

export const publishModule = ({ module, id }: PublishModuleParams) =>
  callAPIHandler("patch", `/api/admin/${module}/publish/${id}`, {}, true);

// Publish all
export interface ModuleItemsStagingList {
  [module: string]: {
    [itemId: string]: {
      [lang: string]: string;
    };
  };
}

export const getStagingList = (): Promise<ModuleItemsStagingList> =>
  callAPIHandler("get", `/api/admin/staging-list`, {}, true);

export const publishAll = (res: ModuleItemsStagingList) =>
  callAPIHandler("patch", "/api/admin/publish-all", res, true);

// Delete module
interface deleteModuleItemParam {
  id: string;
  module: string;
}

export const deleteModuleItem = ({ id, module }: deleteModuleItemParam) =>
  callAPIHandler("delete", `/api/admin/${module}/delete/${id}`, {}, true);

export const revertDeleteModuleItem = ({ id, module }: deleteModuleItemParam) =>
  callAPIHandler("post", `/api/admin/${module}/revert-delete/${id}`, {}, true);

export const permanentDeleteModuleItem = ({
  id,
  module,
}: deleteModuleItemParam) =>
  callAPIHandler(
    "post",
    `/api/admin/${module}/permanent-delete/${id}`,
    {},
    true
  );

// Version
type getModuleVersionListingQueryKey = [
  string,
  string,
  string,
  getModuleListingParam,
  number
];

export const getModuleVersionListing = ({
  queryKey,
}: {
  queryKey: getModuleVersionListingQueryKey;
}): Promise<getModuleListingResponse> => {
  const [
    ,
    module,
    id,
    { limit, sortBy, sortDirection, search, mode },
    page,
  ] = queryKey;
  return callAPIHandler(
    "get",
    `/api/admin/${module}/version-listing/${id}`,
    {
      page,
      limit,
      order: sortBy,
      direction: sortDirection,
      search,
      ...(mode === "export" ? { export: "1" } : {}),
      ...(mode === "deleted" ? { deleted: "1" } : {}),
      ...(mode === "all" ? { listing: "0" } : {}),
    },
    true
  );
};

export const getModuleVersionDetail = ({
  queryKey,
}: {
  queryKey: [string, string, string];
}): Promise<any> => {
  const [, module, vid] = queryKey;
  return callAPIHandler(
    "get",
    module === "poly_model"
      ? `/api/admin/poly_model/version-exploded/${vid}`
      : `/api/admin/${module}/version/${vid}`,
    {},
    true
  );
};

interface requestRollbackParam {
  id: string;
  vid: string | number;
  module: string;
}

export const requestRollback = ({ id, vid, module }: requestRollbackParam) =>
  callAPIHandler("post", `/api/admin/${module}/rollback`, { id, vid }, true);

// Scheduler
interface requestScheduleParam {
  id: string;
  module: string;
}

export const requestSchedulePublish = ({ id, module }: requestScheduleParam) =>
  callAPIHandler(
    "post",
    `/api/admin/${module}/schedule-publish/${id}`,
    {},
    true
  );

export const requestScheduleExpiry = ({ id, module }: requestScheduleParam) =>
  callAPIHandler(
    "post",
    `/api/admin/${module}/schedule-expiry/${id}`,
    {},
    true
  );

interface ChownModuleItemParam {
  module: string;
  belongsTo: string[];
  ids: string[];
}

export const chownModuleItem = ({
  module,
  belongsTo,
  ids,
}: ChownModuleItemParam) =>
  callAPIHandler(
    "post",
    `/api/admin/${module}/chown`,
    { belongs_to: belongsTo, ids },
    true
  );

interface DuplicateModuleItemParam {
  module: string;
  id: string;
}

export const duplicateModuleItem = ({ module, id }: DuplicateModuleItemParam) =>
  callAPIHandler("post", `/api/admin/${module}/duplicate/${id}`, {}, true);

// Reorder module
interface ReorderModuleSequenceParam {
  module: string;
  fromSequence: string;
  toSequence: string;
  actions: [number, number | null][];
}

export const reorderModuleSequence = ({
  module,
  fromSequence,
  toSequence,
  actions,
}: ReorderModuleSequenceParam) =>
  callAPIHandler(
    "post",
    `/api/admin/${module}/store-sequence`,
    {
      from_key: fromSequence,
      to_key: toSequence,
      actions,
    },
    true
  );
