import React, { useCallback, useRef } from "react";

import _ from "lodash";
import clsx from "clsx";

interface Props {
  value: string | undefined;
  options: Record<string, string> | undefined;
  onChange: (newValue: string) => void;
  disabled?: boolean;
  className?: string;
}

function RadioInput({
  value,
  options,
  onChange,
  disabled = false,
  className,
}: Props) {
  const id = useRef(_.uniqueId("radio"));

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!disabled && e.target.value) {
        onChange(e.target.value);
      }
    },
    [disabled, onChange]
  );

  return (
    <div className={clsx([className, "flex"])}>
      {_.entries(options).map(([key, optionValue]) => {
        const optionId = `${id.current}_${key}`;
        return (
          <div key={key} className="mr-6">
            <input
              checked={value === key}
              id={optionId}
              name={id.current}
              onChange={onChangeHandler}
              type="radio"
              value={key}
            />
            <label className="pl-3" htmlFor={optionId}>
              {optionValue}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export default RadioInput;
