import React from "react";

const DataDisplayContext = React.createContext<{
  values: Record<string, string>;
  setValue: (key: string, value: string) => void;
}>({
  values: {},
  setValue: () => {},
});

export const DataDisplayContextProvider = DataDisplayContext.Provider;

export default DataDisplayContext;
