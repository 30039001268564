import { getModuleListing } from "api/module";
import React, { ReactNode } from "react";
import { useQuery } from "react-query";
import tuple from "utils/types/tuple";

type Props = {
  children: ReactNode;
  productId: string;
  taskId: string;
};

const ProductLink = ({ children, productId, taskId }: Props) => {
  const { data } = useQuery(
    tuple([
      "getModuleListing" + productId + taskId,
      "poly_data",
      {
        limit: 1,
        sortBy: "id",
        sortDirection: "desc" as const,
        search: [
          {
            field: "task",
            operator: "==",
            value: taskId,
          },
          {
            field: "product",
            operator: "==",
            value: productId,
          },
        ],
      },
      1,
    ]),
    getModuleListing,
    {
      enabled: !!(taskId && productId),
    }
  );

  if ((data?.count ?? 0) > 0) {
    return <>{children}</>;
  }

  return <></>;
};

export default ProductLink;
