import { LoadingOutlined } from "@ant-design/icons";
import React from "react";

const Spinner = () => {
  return (
    <div className="fixed top-1/2 left-1/2">
      <div className="inline-block transform -translate-x-1/2 -translate-y-1/2">
        <LoadingOutlined style={{ fontSize: 60 }} />
      </div>
    </div>
  );
};

export default Spinner;
