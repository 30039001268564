import useEncryptedToken from "containers/auth/useEncryptedToken";
import React from "react";
import styled from "styled-components";
import Image from "./index";

type Props = {
  uuid: string;
  module: string;
  id: string | number | undefined;
  displayHeight: number;
  displayWidth: number;
  height?: any;
  width?: any;
  className?: string;
};

function ImageServer({
  uuid,
  module,
  id,
  // height,
  // width,
  className,
  displayWidth,
  displayHeight,
}: Props) {
  const encryptedToken = useEncryptedToken();

  if (!encryptedToken) return <></>;

  if (!id) {
    return <></>;
  }

  return (
    <ImageWrapper
      style={{
        width: displayWidth,
        height: displayHeight,
      }}
    >
      <Image
        image={{
          id: String(id),
          module_name: module,
          height: "auto",
          width: "auto",
          uid: uuid,
        }}
        uuid
        width="auto"
        height="auto"
        className={className}
        type="cms"
        encryptedToken={encryptedToken}
      />
    </ImageWrapper>
  );
}

const ImageWrapper = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`;

export default ImageServer;
