import { clearCache } from "api/_requestor";
import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";

const useForceReload = (enabled: boolean) => {
  const [ready, setReady] = useState(false);
  const queryClient = useQueryClient();
  const init = useRef(false);
  useEffect(() => {
    if (init.current || !enabled) {
      return;
    }
    init.current = true;
    try {
      (async () => {
        await Promise.all([clearCache(), queryClient.invalidateQueries()]);
        queryClient.removeQueries();
        setReady(true);
      })();
    } catch (e) {
      console.warn("Failed to clear api cache");
      console.error(e);
    }
  }, [enabled, queryClient]);

  return ready || !enabled;
};

export default useForceReload;
