import { getModuleValue } from "api/module";

import { useQuery } from "react-query";
import { ModuleList } from "types/module";
import tuple from "utils/types/tuple";

type Props = [ModuleList, string | number | undefined];

function useModuleDetail(...args: Props) {
  return useQuery(
    tuple(["getModuleValue", args[0], String(args[1])]),
    getModuleValue,
    { enabled: !!args[1] }
  );
}

export default useModuleDetail;
