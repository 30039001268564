import _ from "lodash";

import { objectToPrettifyString } from "utils/transform/text";
import qs from "query-string";
import { MultiLingualString } from "./../../types/module";

export function captionTranslate<T extends any = undefined>(
  captions: MultiLingualString | string | undefined,
  overrideLangKey?: string,
  fallback?: T
): string | T {
  const search = qs.parse(window.location.search);

  let language: string = (overrideLangKey ?? search.lang ?? "en") as string;
  if (language === "bi") {
    language = "zh-Hant";
  }

  // Get language key from i18n and return the selected language caption
  if (_.isPlainObject(captions) && captions) {
    const availableLanguages = Object.keys(captions);
    // Caption is object
    if (availableLanguages.indexOf(language) !== -1) {
      // Caption is a translation object
      return objectToPrettifyString(captions[language]);
    } else if (availableLanguages.length > 0) {
      // Fallback to 1st lang in the list
      return objectToPrettifyString(captions[availableLanguages[0]]);
    }

    // Caption is other object
    const translatedCaptions = _.mapValues(captions, (value) =>
      captionTranslate(value, overrideLangKey)
    );
    return objectToPrettifyString(translatedCaptions);
  }

  if (typeof captions === "string") return captions;

  // Not translation object or any object passed to caption Translate
  return fallback as T;
}

export const getBothLangContent = (
  value: any,
  overrideLangKey?: string
): string => {
  const englishValue = captionTranslate(value, "en");
  const chineseValue = captionTranslate(value, "zh-Hant");

  if (!englishValue && !chineseValue) {
    return "-";
  }

  const search = qs.parse(window.location.search);
  let language: string = (overrideLangKey ?? search.lang ?? "en") as string;
  if (language === "bi") {
    language = "zh-Hant";
  }

  switch (language) {
    case "zh-Hant":
      return chineseValue || "-";
    case "en":
      return englishValue || "-";
    default:
      return englishValue || "-";
  }
};
