import React from "react";

import Footer from "components/Footer";
import Header from "components/Header";
import PageSizeContent from "components/PageSizeContent";
import Scaffold from "components/Scaffold";
import Title from "components/Title";
import useAdhocDetail from "containers/useAdhocDetail";

type Props = {};

const AppendixAdhocPage = (props: Props) => {
  const { data } = useAdhocDetail();

  return (
    <PageSizeContent size="a4" className="page_appendix" isAdhoc>
      <Scaffold
        header={
          <Header
            title={`SERVICE REPORT`}
            zhTitle={`臨時服務報告`}
            subTitle={undefined}
            zhSubTitle={undefined}
          />
        }
        footer={<Footer isAdhoc />}
      >
        {data?.value?.appendix?.map((item) => (
          <div>
            <Title
              title={item?.caption?.en}
              zhTitle={item?.caption?.["zh-Hant"]}
              size="md"
              bar
            />
            <div
              className="mt-1 mb-6 text-xs"
              dangerouslySetInnerHTML={{ __html: item?.content || "" }}
            />
          </div>
        ))}
      </Scaffold>
    </PageSizeContent>
  );
};

export default AppendixAdhocPage;
