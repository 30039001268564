import React from "react";
import useLang from "utils/hooks/useLang";
import HeaderPatten from "./HeaderPatten";

type Props = {
  title: string;
  zhTitle: string;
  subTitle: string | undefined;
  zhSubTitle: string | undefined;
};

const Header = ({ title, subTitle, zhTitle, zhSubTitle }: Props) => {
  const [lang, isLang, isBi] = useLang();

  return (
    <div className="relative pt-[10px]">
      <div className="absolute left-0 top-0">
        <HeaderPatten />
      </div>
      <div className="bg-[#003189] text-white min-h-[65px] flex items-center pl-[150px] py-3 pr-3">
        <div className="border-l-[#E61E25] border-l-4 pl-3">
          <h1 className="leading-none text-[19px]">
            {isBi || lang === "en" ? title : zhTitle}
          </h1>
          {(zhSubTitle || subTitle) && (
            <div className="flex">
              {isLang("en") && subTitle && <h2 className="mr-2">{subTitle}</h2>}
              {isLang("zh-Hant") && zhSubTitle && (
                <h2 className="">{zhSubTitle}</h2>
              )}
            </div>
          )}
        </div>
        {isBi && (
          <h1 className="mt-[2px] ml-1 text-[18px] font-medium whitespace-nowrap">
            {zhTitle}
          </h1>
        )}
      </div>
    </div>
  );
};

export default Header;
