import moment from 'moment';

export const dateToString = (
  dateNumber: number | null | undefined,
  type: 'date' | 'datetime' | 'time',
) => {
  if (!dateNumber) return '-';
  switch (type) {
    case 'date':
      return moment(dateNumber).format('YYYY-MM-DD');
    case 'datetime':
      return moment(dateNumber).format('YYYY-MM-DD HH:mm:ss');
    case 'time':
      return moment(dateNumber).format('h:mm:ss A');
  }
};
