import clsx from "clsx";
import React, { ReactNode } from "react";
import { DataCell } from ".";

type Props = {
  rowData: DataCell[] | undefined;
  row: DataCell | undefined;
  columnHeader: ReactNode;
  renderHeader: (colData: DataCell[], colIndex?: number) => ReactNode;
};

const renderBaseCell = (
  cell: DataCell,
  customClassName?: string,
  index?: number
) => {
  return (
    <td
      colSpan={cell.span}
      className={clsx([customClassName, cell.className])}
      key={`${cell.cellKey}-${cell.colIndex}-${index}`}
    >
      <div className={cell.textClassName}>{cell.value}</div>
    </td>
  );
};

const TableCell = ({
  rowData = [],
  row,
  columnHeader,
  renderHeader,
}: Props) => {
  if (row?.component) {
    return (
      <>
        {/* Custom Row */}
        <tr className={clsx([row?.className, "row-component"])}>
          <td colSpan={row?.span ?? rowData.length + 1} className="row plain">
            {row.component}
          </td>
        </tr>
        {/* Base header */}
        {row?.header && (
          <tr className={clsx([row?.className, "row-base-header"])}>
            {columnHeader}
          </tr>
        )}
        {/* Custom header render */}
        {renderHeader && row?.renderHeaderData && (
          <tr className={clsx([row?.className, "row-render-header"])}>
            {renderHeader(row.renderHeaderData[0], row.renderHeaderData[1])}
          </tr>
        )}
      </>
    );
  }

  return (
    <tr>
      {/* Standard Row */}
      {row && renderBaseCell(row, "row", -1)}
      {/* Standard Cell */}
      {rowData.map((cell, j) => renderBaseCell(cell, undefined, j))}
    </tr>
  );
};

export default TableCell;
