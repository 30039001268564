import PageSizeContent from "components/PageSizeContent";
import useModuleDetail from "containers/useModuleDetail";
import moment from "moment";
import React from "react";
import CoverFooter from "./CoverFooter";
import useLang from "utils/hooks/useLang";
import useScroll from "utils/hooks/useScroll";
import useAdhocDetail from "containers/useAdhocDetail";

const CoverAdhocPage = () => {
  const { data } = useAdhocDetail();
  const { data: clientData } = useModuleDetail("client", data?.value?.client);

  useScroll();

  // const { data: projectData } = useQuery(
  //   tuple(["getModuleValue", "project", data?.value?.project as string]),
  //   getModuleValue,
  //   { enabled: !!data?.value?.project }
  // );

  const [lang, isLang] = useLang();

  const { data: iesData } = useModuleDetail(
    "ies_company",
    data?.value.ies_company
  );

  return (
    <PageSizeContent
      className="page_cover"
      size="a4"
      style={{
        backgroundImage: "url('/images/cover.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
      isAdhoc
    >
      <div className="text-[18px] absolute z-10 top-12 transform -translate-x-1/2 left-1/2">
        {/* TODO: Update font-style */}
        <img
          src="/images/logo.png"
          alt="logo"
          className="absolute w-[134px] h-auto left-[-165px] top-[-8px]"
        />
        <h6>{iesData?.value?.name?.["zh-Hant"]}</h6>
        <h6 className="font-medium">{iesData?.value?.name?.["en"]}</h6>
      </div>
      <div className="absolute z-20 top-1/2 transform -translate-y-1/2 right-0 left-12 py-12 px-12 bg-[#0C0C3790]">
        <div className="border-[#E61E25] border-l-[9px] pl-8">
          {isLang("en") && (
            <h1 className="text-[46px] font-medium text-white leading-none mb-3">
              IES SERVICE REPORT
            </h1>
          )}
          {isLang("zh-Hant") && (
            <h2 className="text-[42px] font-medium text-white">
              IES 臨時服務報告
            </h2>
          )}
          {isLang("en") && (
            <h2 className="text-[38px] font-medium text-white">
              {moment(data?.value?.report_datetime || data?.value?.start_date)
                .locale("en")
                .format("DD MMM YYYY")
                .toUpperCase()}
            </h2>
          )}
          {isLang("zh-Hant") && (
            <h4 className="text-[32px] font-medium text-white">
              {moment(data?.value?.report_datetime || data?.value?.start_date)
                .locale("zh-hk")
                .format("YYYY年MM月DD日")
                .toUpperCase()}
            </h4>
          )}
          <h5 className="text-[29px] font-medium text-white mt-4">
            {data?.value?.client_name ||
              clientData?.value?.company_name?.[lang]}
          </h5>
          <div className="flex justify-between mt-4">
            <h5 className="text-[24px] text-white basis-full font-normal">
              {data?.value?.report_title?.[lang] || data?.value?.title}
            </h5>
          </div>
        </div>
      </div>
      <CoverFooter type="adhoc" />
    </PageSizeContent>
  );
};

export default CoverAdhocPage;
