import React from "react";
import useLang from "utils/hooks/useLang";

type Props = {
  children: any;
};

const LangText = ({ children }: Props) => {
  const [, isLang] = useLang();

  if (!children) {
    return <>-</>;
  }

  return (
    <>
      {isLang("en") && children?.en}{" "}
      {isLang("zh-Hant") && children?.["zh-Hant"]}
    </>
  );
};

export default LangText;
