import Title from "components/Title";
import { isString } from "lodash";
import React, { useMemo } from "react";

interface Props {
  html: string;
  asrisk?: boolean;
  remarksTitle?: boolean;
}

function TextareaDisplay({ html, asrisk, remarksTitle = false }: Props) {
  const htmlWithBreaks = useMemo(() => {
    if (isString(html)) {
      return html.replaceAll("\n", "<br/>");
    }
    return undefined;
  }, [html]);

  return (
    <div>
      {remarksTitle && (
        <Title
          title="Remarks"
          zhTitle="備註"
          prefix={
            asrisk ? (
              <span className="text-xs ml-1">
                (<span className="text-[#E61E25]">*</span>)
              </span>
            ) : undefined
          }
        />
      )}
      {!!htmlWithBreaks && (
        <div
          className="p-2 bg-[#F0F0F0] text-xs leading-tight"
          style={
            htmlWithBreaks.length > 4000 ? { fontSize: "0.666rem" } : undefined
          }
          dangerouslySetInnerHTML={{ __html: htmlWithBreaks }}
        />
      )}
    </div>
  );
}

export default TextareaDisplay;
