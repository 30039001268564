import React, { useEffect, useRef } from "react";

import { safeJSONParse } from "utils/transform/json";

import { isUndefined } from "lodash-es";
import { captionTranslate } from "utils/transform/multilang";
import { uniqueId } from "lodash-es";
import { Column } from "@antv/g2plot";
import Title from "components/Title";

type Props = {
  replies: any[] | undefined;
  className?: string;
  graph:
    | {
        graph_caption: string;
        graph_description: string;
        poly_model_index: number;
        product: string;
      }
    | undefined;
};

const ProductionGraph = ({ graph, replies, className }: Props) => {
  const id = useRef(uniqueId("graph_"));
  const rendered = useRef(false);

  // Get all answers
  const answersData = replies;

  const graphData = answersData?.map((s) => {
    const data = safeJSONParse(s.data, []);
    if (!graph?.poly_model_index) {
      return undefined;
    }
    return data?.[graph.poly_model_index];
  });

  const equipmentData = replies?.map((s) =>
    captionTranslate(s.equipmentData?.name)
  );

  const data = graphData?.map((s, i) => ({
    value: s ? Number(s) : 0,
    label: equipmentData?.[i],
  }));

  useEffect(() => {
    if (!graph || isUndefined(graph.poly_model_index) || !data) {
      return;
    }

    // Prevent double render
    if (rendered.current) {
      return;
    }
    rendered.current = true;

    // Column
    const columnPlot = new Column(id.current, {
      data,
      xField: "label",
      yField: "value",
      color: "#003189",
    });

    columnPlot.render();
  }, [data, graph]);

  return (
    <div className={className}>
      {graph?.graph_caption && <Title title={graph.graph_caption} zhTitle="" />}
      <div
        id={id.current}
        className="mt-4"
        style={{ maxWidth: (data?.length || 0) * 300, width: "100%" }}
      />
      {graph?.graph_description && (
        <p className="mt-4">{graph.graph_description}</p>
      )}
    </div>
  );
};

export default ProductionGraph;
