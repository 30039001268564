import { ReactNode, useEffect, useState } from "react";
import React from "react";
import qs from "query-string";
import { useHistory } from "react-router-dom";

type Props = {
  children: ReactNode | JSX.Element;
};

const DecryptedToken = ({ children }: Props) => {
  const [updated, setUpdated] = useState(false);
  const url = qs.parse(window.location.search);
  const history = useHistory();

  useEffect(() => {
    if (!url.s || updated) {
      return;
    }
    try {
      window.localStorage.setItem(
        "s",
        window.atob(
          window
            .atob(url.s as string)
            .split("")
            .reverse()
            .join("")
        )
      );
      const search = qs.parse(history.location.search);
      history.replace({
        ...history.location,
        search: qs.stringify({
          ...search,
          s: undefined,
          t: "1",
        }),
      });
    } catch (e) {
      console.warn(e);
    }
    setUpdated(true);
  }, [history, updated, url.s]);

  if (!url.s) {
    return <>{children}</>;
  }

  if (updated) {
    return <>{children}</>;
  }

  return <></>;
};

export default DecryptedToken;
