import { ApiErrorResponse, ApiOkResponse } from "apisauce";
import qs from "query-string";
import { getTypedRequestor } from "./_requestor";
import { setTokenHeader } from "./_token";

// Only support maximum 2d
// TODO support deep nested
const objectToFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      data[key].forEach((_value, index) => {
        formData.append(`${key}[${index}]`, data[key][index]);
      });
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
};

export interface RespondType {
  [x: string]: any;
}

export interface callAPIHandlerOption {
  cache?: boolean | number;
  withFiles?: boolean;
  redirect?: boolean;
  raw?: boolean;
  flatten?: boolean;
  postProcessData?: (data: any) => any;
  skipMocker?: boolean;
  language?: string;
  token?: string;
}

interface callAPIHandlerExtraOption {
  headers: Record<string, string>;
}

const defaultConfig = {
  cache: undefined,
  withFiles: false,
  redirect: true,
  raw: false,
};

function flattenParams(
  object: Record<string, any>,
  parentKey?: string
): Record<string, unknown> {
  let flatten = {} as { [key: string]: unknown };
  Object.entries(object).forEach(([key, value]) => {
    const newKeyName = parentKey ? `${parentKey}[${key}]` : key;
    if (typeof value === "object" && value !== null) {
      flatten = { ...flatten, ...flattenParams(value, newKeyName) };
    } else {
      flatten[newKeyName] = value;
    }
  });
  return flatten;
}

export type Method = "get" | "post" | "patch" | "put" | "delete";

// Call API with token
export async function callAPIHandler<T>(
  type: Method,
  apiPath: string,
  inputData: Record<string, any>,
  withToken: boolean,
  config?: callAPIHandlerOption,
  extraOptions?: callAPIHandlerExtraOption
): Promise<T & RespondType> {
  try {
    // Merge config with default config
    const apiConfig = { ...defaultConfig, ...config };

    // Add Extra Data to request body
    let data: Record<string, any> = {
      ...inputData,
      lang: apiConfig.language ?? qs.parse(window.location.search).lang,
      p6m: "ies-report",
      // Flag api to apply cache
      _cache: apiConfig.cache === false ? "0" : "1",
    };

    // Flatten
    if (apiConfig.flatten) {
      data = flattenParams(data);
    }

    // Use form data if data have files
    const requestData = apiConfig.withFiles ? objectToFormData(data) : data;
    const extraHeaders: any = {};
    if (apiConfig.withFiles) {
      extraHeaders["Content-Type"] = "multipart/form-data";
    }

    // Get Auth Token into header
    var token: string | undefined = undefined;
    if (withToken) {
      if (apiConfig.token) {
        token = apiConfig.token;
      } else {
        token = (await setTokenHeader(
          apiConfig.redirect === false,
          apiPath
        )) as string;
      }
    }

    // Send request
    const res:
      | ApiOkResponse<any>
      | ApiErrorResponse<any> = await getTypedRequestor(type)(
      apiPath,
      requestData,
      {
        ...extraOptions,
        cache: {
          maxAge:
            typeof apiConfig.cache === "number" && apiConfig.cache
              ? apiConfig.cache
              : undefined,
        },
        headers: {
          ...extraHeaders,
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {}),
          ...(extraOptions?.headers ? extraOptions.headers : {}),
        },
      }
    );

    if (config?.raw) {
      return res as any;
    }

    // Unauthorized
    if (res.status === 401) {
      throw new Error("unauthorized");
    }

    if (!res.ok) {
      // Normal Error
      if (res.data?.error) {
        // Error response
        throw res.data;
      }

      // Blob error
      if (
        res.data?.type === "application/json" &&
        res.data?.constructor?.name === "Blob"
      ) {
        const blobText = await res.data.text();
        const blobRes = JSON.parse(blobText);
        if (blobRes.error) {
          throw blobRes;
        }
      }
      throw new Error("failed-response");
    }

    // Handle response
    if (res.data) {
      // Success response
      if (config?.postProcessData) {
        return config.postProcessData(res.data);
      }
      return res.data;
    }
    throw new Error("invalid-response");
  } catch (error) {
    const message = error.errorMessage || error.message;
    const code = error.errorCode || error.message;
    // eslint-disable-next-line no-throw-literal
    throw { error: true, errorMessage: message, errorCode: code };
  }
}
