import React from "react";

function Divider() {
  return (
    <div className="py-5">
      <hr />
    </div>
  );
}

export default Divider;
