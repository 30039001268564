import clsx from "clsx";
import IconCheck from "components/Icon/IconCheck";
import React from "react";
import useLang from "utils/hooks/useLang";

const ProductLegend = ({ className }: { className?: string }) => {
  const [, isLang] = useLang();

  return (
    <div
      className={clsx([
        "text-right text-[10px] flex flex-col justify-center whitespace-nowrap",
        className,
      ])}
    >
      {isLang("en") && (
        <div>
          <IconCheck /> - Normal / X - Abnormal / O - Repaired / O/S -
          Outstanding
        </div>
      )}
      {isLang("zh-Hant") && (
        <div>
          <IconCheck /> - 正常 / X - 異常 / O - 已維修 / O/S - 未完成
        </div>
      )}
    </div>
  );
};

export default ProductLegend;
