/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import * as React from "react";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./styles/tailwind-output.css";
import * as ReactDOM from "react-dom";

// Import root app
import App from "./app";

const MOUNT_NODE = document.getElementById("root") as HTMLElement;

interface Props {
  Component: typeof App;
}

const RenderApp = ({ Component }: Props) => <Component />;
const render = (Component: typeof App) => {
  ReactDOM.render(<RenderApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["./app"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    const App = require("./app").default;
    render(App);
  });
}

// Render App
render(App);
