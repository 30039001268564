import React, { useEffect } from "react";
import Title from "components/Title";
import useTaskProductData from "./useTaskProductData";
import _ from "lodash";

export function isDescending(arr: number[]) {
  return arr.every(function (x, i) {
    return i === 0 || x <= arr[i - 1];
  });
}

const ProductIdentify = ({
  productId,
  setIsDefaultOrder,
}: {
  productId: string;
  setIsDefaultOrder: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const joinData = useTaskProductData();

  const productOnlyData = joinData?.filter(
    // eslint-disable-next-line eqeqeq
    (p) => p.product_data?.id == productId
  );

  const productIds = productOnlyData?.map((s) => Number(s.id));
  /* TODO: use cms order, not reverse here */
  const isDefaultOrder =
    !_.isEmpty(productIds) && productIds ? isDescending(productIds) : false;

  const finalData = isDefaultOrder
    ? _.clone(productOnlyData)?.reverse()
    : productOnlyData;

  useEffect(() => {
    setIsDefaultOrder(isDefaultOrder);
  }, [isDefaultOrder, setIsDefaultOrder]);

  return (
    <>
      <Title
        title="Report for the following items"
        zhTitle="報告包括以下產品"
        size="xs"
      />
      <div className="flex flex-wrap">
        {finalData?.map((s, i) => (
          <ProductIdentifyItem item={s} key={s.id} index={i} />
        ))}
      </div>
    </>
  );
};

const ProductIdentifyItem = ({ item, index }: { item: any; index: number }) => {
  const values = [
    item.model_no ?? " - ",
    item.sn ?? " - ",
    item.other_ref_number ?? " - ",
  ];
  return (
    <div className="flex items-center flex-grow-0 flex-shrink-0 basis-1/2 max-w-1/2 w-1/2 mt-1 odd:pr-10 even:pl-10 text-xs">
      <div className="w-[150px] text-[#01328A] mr-3 font-bold break-words">
        {index + 1}. {item.custom_identifier || "-"}
      </div>
      <div className="bg-[#F0F0F0] py-[2px] px-4 w-full">
        {values.join("/")}
      </div>
    </div>
  );
};

export default ProductIdentify;
