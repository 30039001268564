import FooterDetailWhite from "./FooterDetailWhite";
import React from "react";

interface Props {
  type: "task" | "adhoc";
}

const CoverFooter = ({ type }: Props) => {
  return (
    <div className="absolute bottom-0 left-0 rigth-0 z-10 w-full">
      <FooterDetailWhite type={type} />
      <div className="absolute right-0 bottom-0 z-30">
        <svg
          id="Deco"
          xmlns="http://www.w3.org/2000/svg"
          width="117"
          height="259"
          viewBox="0 0 117 259"
        >
          <path
            id="Path_3"
            data-name="Path 3"
            d="M-107.6,1058.438l-37.363,64.336h72.373Z"
            transform="translate(144.959 -864)"
            fill="#bc1f2e"
          />
          <path
            id="Path_1"
            data-name="Path 1"
            d="M-27.959,862.877l-103.314,152.506,54.885,106.494h48.429Z"
            transform="translate(144.959 -862.877)"
            fill="#0b4a8e"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M-120.283,1036.625l92.59,52.463v33.771H-76.5Z"
            transform="translate(144.693 -863.859)"
            fill="#0a3c7a"
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M-28,862.877l-58.307,88.01L-28,1051.051Z"
            transform="translate(145 -862.877)"
            fill="#0b488d"
          />
        </svg>
      </div>
    </div>
  );
};

export default CoverFooter;
