import React, { ReactNode, useEffect, useRef, useState } from "react";
import ItemOffset from "./ItemOffset";
import qs from "query-string";
import usePreview from "utils/hooks/usePreview";
import { useIsFetching } from "react-query";
import _ from "lodash";
import Spinner from "components/Spinner";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

type Props = {
  header: ReactNode;
  footer: ReactNode;
  children: ReactNode;
  strict?: boolean;
  className?: string;
};

const getQueryString = (x: string | string[] | null) => {
  if (_.isArray(x)) {
    return x[0];
  }
  return x;
};

const Scaffold = ({ header, footer, children, strict, className }: Props) => {
  const url = qs.parse(window.location.search);
  const start = Number(getQueryString(url.start) || 0);
  const lastStart = getQueryString(url[`s_${start}`]);
  const [nextStart, setNextStart] = useState(-1);
  const isPreview = usePreview();
  const [loaded, setLoaded] = useState(!isPreview);
  const [ready, setReady] = useState(false);
  const heights = useRef<number[]>([]);
  const mainWrapperHeight = useRef(0);

  const history = useHistory();

  const rects = useRef<DOMRect[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isPreview && ready) {
      setTimeout(() => {
        setLoaded(true);
      }, 600);
    }
  }, [isPreview, ready]);

  useEffect(() => {
    if (strict) {
      return;
    }
    const interval = setInterval(() => {
      let mainHeight = ref.current?.getBoundingClientRect().height ?? 180;
      const wrapperHeight = mainHeight - 180;
      mainWrapperHeight.current = wrapperHeight;

      if (!wrapperHeight || !ref.current) {
        return;
      }

      let updated = false;
      let bottom = 0;
      rects.current?.forEach((s, i) => {
        heights.current[i] = s.height;
        if (s.height === 0 || updated) {
          return;
        }
        bottom += s.height;
        if (bottom > wrapperHeight) {
          updated = true;
          if (i === start) {
            // Special case where component too big and overflow to next
            setNextStart(i + 1);
          } else {
            setNextStart(i);
          }
        }
      });

      if (!updated) {
        setNextStart(-1);
      }
      // Delayed time to allow all table to proper render 1st, before determining the next start
    }, 750);

    return () => {
      clearInterval(interval);
    };
  }, [start, strict]);

  // Check if loaded
  const isFetching = useIsFetching();
  const loading = useRef(true);
  const count = useRef(0);
  useEffect(() => {
    loading.current = !!isFetching;
  }, [isFetching]);

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    const timer = setInterval(() => {
      if (count.current > 1) {
        document.getElementsByTagName("body")[0].style.overflow = "auto";
        setReady(true);
        clearInterval(timer);
      }
      if (!loading.current) {
        count.current += 1;
      }
    }, 250);
  }, []);

  if (strict) {
    return <>{children}</>;
  }

  const flatChildren = (children as any[]).flat().filter((s) => !!s);

  const href =
    history.location.pathname + history.location.search + history.location.hash;

  return (
    <div
      className={clsx(["flex flex-col h-full relative", className])}
      ref={ref}
    >
      {header}
      {isPreview && !loaded && <Spinner />}
      <ScaffoldMain
        className={clsx([
          "scaffold",
          "basis-full pt-10 px-10",
          isPreview && !loaded && "invisible",
        ])}
      >
        {flatChildren?.map((s, i) => {
          return (
            <ItemOffset
              key={i}
              index={i}
              setParentRect={(rect) => {
                rects.current[i] = rect;
              }}
              hidden={i < start}
              wrapperHeight={mainWrapperHeight.current}
              invisible={nextStart !== -1 && i >= nextStart}
              offsetHeight={heights.current
                .slice(0, i)
                .reduce((s, c) => s + (c ?? 0), 0)}
            >
              {s}
            </ItemOffset>
          );
        })}
      </ScaffoldMain>
      <div className="absolute bottom-0 left-0">{footer}</div>
      {nextStart > -1 ? (
        <a
          href={nextStartUrl(nextStart, start, href)}
          className="link"
          style={{ display: "none" }}
        >
          -
        </a>
      ) : (
        <></>
      )}
      {isPreview && lastStart && (
        <div className="fixed bottom-5 left-5">
          <a
            href={nextStartUrl(
              lastStart,
              getQueryString(url[`s_${lastStart}`]),
              href
            )}
            className="bg-white py-1 px-4 inline-block border border-black rounded-md"
          >
            Previous Page
          </a>
        </div>
      )}
      {isPreview && nextStart > -1 && (
        <div className="fixed bottom-5 right-5">
          <a
            href={nextStartUrl(nextStart, start, href)}
            className="bg-white py-1 px-4 inline-block border border-black rounded-md"
          >
            Next Page
          </a>
        </div>
      )}
    </div>
  );
};

const nextStartUrl = (
  nextStart: number | string,
  lastStart: string | number | null,
  href: string
) => {
  const res = qs.parseUrl(window.location.origin + href, {
    parseFragmentIdentifier: true,
  });
  return qs.stringifyUrl({
    ...res,
    query: {
      ...res.query,
      [`s_${nextStart}`]: lastStart,
      start: nextStart,
      lastStart: lastStart,
    },
  });
};

const ScaffoldMain = styled.div`
  /* Uncomment this to prevent accident hidden for next 1st item */
  & > *:not(.invisible) + .invisible:nth-child(2) {
    visibility: visible;
  }

  /* Page break */
  & > .page-break {
    padding-top: 99999px;
  }

  & > .hidden + .page-break {
    padding-top: 0px;
  }
`;

export default Scaffold;
