import ReadyContext from "app/RootNavigation/ReadyContext";
import clsx from "clsx";
import _ from "lodash";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useIsFetching } from "react-query";
import { RemainingHeightContextProvider } from "./RemainingHeightContext";

type Props = {
  index: number;
  children: ReactNode;
  setParentRect: (d: DOMRect) => void;
  invisible: boolean;
  hidden: boolean;
  offsetHeight: number;
  wrapperHeight: number;
  className?: string;
};

// TO be checked if this works
const softRemove = true;

const ItemOffset = ({
  index,
  children,
  setParentRect,
  invisible,
  hidden,
  wrapperHeight,
  offsetHeight,
  className,
}: Props) => {
  const [rect, setRect] = useState<DOMRect>();

  const ref = useRef<HTMLDivElement>(null);
  const count = useRef(0);
  const isFetching = useIsFetching();

  const loading = useRef(true);

  useEffect(() => {
    loading.current = !!isFetching;
  }, [isFetching]);

  useEffect(() => {
    const calc = () => {
      if (window.scrollY !== 0 || count.current > 10) {
        return;
      }
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        setRect(rect);
        setParentRect(rect);
        if (!loading.current) {
          count.current++;
        }
      }
    };
    calc();
    setInterval(calc, 250);
  }, [setParentRect]);

  const ready = useContext(ReadyContext);
  const [disabledHeight, setDisabledHeight] = useState<number>();
  const isShow = !ready ? true : !invisible && !hidden;

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (ready && !disabledHeight) {
      if (invisible || hidden) {
        timer = setTimeout(() => {
          setDisabledHeight(rect?.height);
        }, 75);
      }
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [hidden, invisible, disabledHeight, ready, rect]);

  return (
    <div
      ref={ref}
      className={clsx([
        `top_${rect?.top} height_${rect?.height} index_${index}`,
        invisible && "invisible",
        hidden && "hidden",
        !_.isUndefined(disabledHeight) && "removed",
        className,
        Array.from(ref.current?.children || []).some((s) =>
          s.classList.contains("page-break")
        ) && "page-break",
      ])}
    >
      {!softRemove && !_.isUndefined(disabledHeight) && (
        <div style={{ height: disabledHeight }} />
      )}
      {((isShow && !_.isUndefined(disabledHeight)) || softRemove) && (
        <RemainingHeightContextProvider
          value={{
            offsetHeight: Math.round(offsetHeight),
            wrapperHeight: Math.round(wrapperHeight),
          }}
        >
          {children}
        </RemainingHeightContextProvider>
      )}
    </div>
  );
};

export default ItemOffset;
