/* eslint-disable eqeqeq */
import useModuleListing from "containers/useModuleListing";
import useTaskDetail from "containers/useTaskDetail";
import _ from "lodash";

import { find } from "lodash-es";

const useTaskProductData = () => {
  const { data } = useTaskDetail();
  const { data: equipmentsData } = useModuleListing(
    "equipment",
    data?.value?.equipments
  );
  const groupedProductIds = (equipmentsData?.value || [])
    .map((s) => s.product)
    .filter((k) => !!k);
  const { data: productData } = useModuleListing("product", groupedProductIds);

  if (!productData?.value) {
    return undefined;
  }

  let joinData = equipmentsData?.value?.map((s) => ({
    ...s,
    product_data: find(productData?.value, (k) => k.id == s.product),
  }));

  if (data?.value?.equipments_order) {
    joinData = _.sortBy(joinData, (s) => {
      return data.value.equipments_order.indexOf((s as any).id);
    });
  }

  return joinData as any[] | undefined;
};

export default useTaskProductData;
