import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    font-family: 'Noto Sans TC', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    margin: 0;
    background-color: #fff;
    -webkit-print-color-adjust: exact;
  }

  body {
    overflow: hidden;
  }

  body > iframe {
    display: none!important;
  }
`;
