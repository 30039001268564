import NodeRSA from "node-rsa";

const envKey = process.env.REACT_APP_PUBLIC_KEY;

const key = new NodeRSA(
  `-----BEGIN PUBLIC KEY-----${envKey}-----END PUBLIC KEY-----`,
  "pkcs8-public"
);

export const encryptToken = (token: string, expireInSeconds = 120) => {
  if (!token) return null;

  const t = JSON.stringify({
    t: token,
    e: new Date().getTime() + expireInSeconds * 1000,
  });

  const splitAs = 214;

  let i = 0;
  const overflowBuffer = 9999;

  const u: string[] = [];

  while (i < t.length && i < overflowBuffer && t.length > 0) {
    const x = t.substr(i, splitAs);
    const e = key.encrypt(x, "base64");
    u.push(encodeURIComponent(e));
    i += splitAs;
  }

  const encodeUrl = `t3n[]=${u.join("&t3n[]=")}`;
  return encodeUrl;
};

export const decryptString = (str: string) => {
  return key.decryptPublic(str).toString();
};
