import { getModuleVersionDetail } from "api/module";

import React, { useCallback, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { captionTranslate } from "utils/transform/multilang";
import tuple from "utils/types/tuple";
import { safeJSONParse } from "utils/transform/json";
import Table, { DataCell } from "components/Table";
import useLang from "utils/hooks/useLang";
import clsx from "clsx";
import useRenderTable from "../Table/useRenderTable";
import DataDisplay from "./DataDisplay/DataDisplay";
import Title from "components/Title";
import ProductLegend from "components/Table/ProductLegend";
import _, { isUndefined, range } from "lodash";

type Props = {
  polyModelVid: string | undefined;
  replies: any[] | undefined;
};

interface GroupDataCell extends DataCell {
  groupedValue?: DataCell[];
}

const flattenData = (
  data: GroupDataCell[] | undefined
): DataCell[] | undefined => {
  if (!data) {
    return undefined;
  }
  let newData: DataCell[] = [];
  data.forEach((s) => {
    newData.push(s);
    if (s.groupedValue) {
      newData = [...newData, ...s.groupedValue];
    }
  });
  return newData;
};

const flattenDeepData = (data: GroupDataCell[][] | undefined) => {
  if (!data) {
    return undefined;
  }
  let newData: GroupDataCell[][] = [];
  let i = 0;
  data.forEach((row) => {
    let groupedCount = 0;
    row.forEach((k, o) => {
      if (!newData[i]) {
        newData[i] = [];
      }
      newData[i][o] = k;

      if (k.groupedValue) {
        groupedCount = Math.max(k.groupedValue.length, groupedCount);
        k.groupedValue.forEach((h, j) => {
          if (!newData[i + j + 1]) {
            newData[i + j + 1] = [];
          }
          newData[i + j + 1][o] = h;
        });
      }
    });
    i += groupedCount + 1;
  });

  // Fill empty cell
  const colCount = newData.reduce((a, b) => Math.max(a, b?.length || 0), 0);
  newData.forEach((row) => {
    const rowCellKey = row.reduce((a, b) => Math.max(a, b?.cellKey || 0), 0);
    range(colCount).forEach((colIndex) => {
      if (isUndefined(row[colIndex])) {
        row[colIndex] = {
          value: <DataDisplay value={undefined} field={{}} />,
          cellKey: rowCellKey,
        };
      }
    });
  });

  return newData;
};

const safeData = (dataStr: string | undefined, model?: any): any[] => {
  let data = safeJSONParse(dataStr);

  // Handle incorrect indexed array turned to object
  if (_.isPlainObject(data)) {
    // Check if all keys in data is numeric
    const dataKeys = _.keys(data);
    if (dataKeys.every((s) => !isNaN(Number(s)))) {
      // Should be a indexed array, somehow php turned this into object
      // Don't ask why, just enjoy the pain
      const newData = [];
      dataKeys.forEach((k) => {
        newData[k] = data[k];
      });
      return newData;
    }
  }

  if (!_.isArray(data)) {
    if (model) {
      // Check if model
      return model.map((s) => {
        if (s.type === "group") {
          return new Array(s.children?.length || 0).fill(null);
        }
        return null;
      });
    }
    return [];
  }

  // Original data array
  return data.map((s, i) => {
    if (model[i]?.type === "group" && !s) {
      return new Array(model[i]?.children?.length || 0).fill(null);
    }
    return s;
  });
};

const useProductionQuestionDetail = ({
  polyModelVid,
  replies: inputReplies,
}: Props) => {
  const replies = _.uniqBy(inputReplies, (s) => s.equipment);

  const { data: polyModel } = useQuery(
    tuple(["getModuleVersionDetail", "poly_model", polyModelVid as string]),
    getModuleVersionDetail,
    { enabled: !!polyModelVid }
  );

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!ref.current) {
        return;
      }

      let isFirstHeader = false;
      const existingRows = ref.current.querySelectorAll("tbody:not(.hidden)");
      if (existingRows.item(0)?.querySelectorAll(".sub-row-header")) {
        // First row is header
        isFirstHeader = true;
      }

      // check for hidden
      const titles = ref.current.querySelectorAll("tbody.hidden .lg-title");
      titles.forEach((s, i) => {
        const parent: any = s.parentNode?.parentNode?.parentNode?.parentNode;
        if (parent) {
          if (i === titles.length - 1 && !isFirstHeader) {
            // Last
            parent.classList.add("header");
          } else {
            // Start
            parent.classList.remove("header");
          }
        }
      });

      if (isFirstHeader) {
        existingRows.item(0).classList.add("header");
      }

      // Apply last row parent
      const lastRows = ref.current.querySelectorAll("tbody");
      lastRows.forEach((parent, i) => {
        const lastAnsRowQuery = parent.querySelectorAll(".last-ans-row");
        if (lastAnsRowQuery.length > 0) {
          // Last
          parent.classList.add("last-ans-row-parent");
        } else {
          // Start
          parent.classList.remove("last-ans-row-parent");
        }
      });
    }, 250);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // Get all answers
  const renderTable = useRenderTable();

  const handler = useCallback(
    (isDefaultOrder: boolean, columnsPerPage: number | undefined) => {
      let polyModelModel = polyModel?.model;
      let answersData = replies;
      const isEmptySection =
        polyModelModel?.[0] && polyModelModel[0].type !== "section";

      // No section as the beginning
      if (isEmptySection) {
        polyModelModel = [
          {
            type: "section",
            caption: { en: "QUESTION SECTION", "zh-Hant": "問題部分" },
          },
          ...polyModelModel,
        ];
      }

      const rawData = polyModelModel?.map((field, i) => {
        if (!answersData) {
          return [{ value: "-", className: "invisible" }];
        }
        const safeMappedData = answersData.map((s) =>
          safeData(s?.data, polyModel?.model)
        );

        return safeMappedData.map((data) => {
          if (isEmptySection) {
            // Offset data by 1
            data = [null, ...data];
          }

          if (field.type === "section") {
            return {
              cellKey: i + 1,
              value: null,
            };
          }

          const dataValue = _.isArray(data?.[i]) ? data[i] : null;

          if (field.type === "group" && dataValue) {
            // Skip supporting description if is all empty
            const skipEmptySupporting =
              field.enable_image_supporting &&
              dataValue.every((d) => {
                return !d?.[2];
              });

            return {
              value: "　",
              cellKey: i,
              groupedValue: dataValue
                .map((k, j) => {
                  if (skipEmptySupporting && j === 2) {
                    return null;
                  }
                  if (
                    field.enable_image_supporting &&
                    (j === 3 || j === 4) // Skip image descriptions and disable image descriptions
                  ) {
                    return null;
                  }
                  return {
                    cellKey: i,
                    ref: k,
                    value: (
                      <DataDisplay
                        key={`${i}-${j}`}
                        value={k}
                        type={field.children?.[j]?.type}
                        field={field.children?.[j]}
                      />
                    ),
                  };
                })
                .filter((s) => !!s),
            };
          }

          return {
            cellKey: i,
            value: (
              <DataDisplay
                key={i}
                value={data?.[i]}
                type={field.type}
                field={field}
              />
            ),
          };
        });
      });

      let data: DataCell[][] = flattenDeepData(rawData) ?? [];

      let equipmentData: DataCell[] =
        replies?.map((s, i) => ({
          value: captionTranslate(s.equipmentData?.custom_identifier),
          cellKey: i,
        })) ?? [];

      // Update order
      if (isDefaultOrder) {
        if (data) {
          data = data.map((s) => _.clone(s).reverse());
        }
        if (equipmentData) {
          equipmentData = _.clone(equipmentData).reverse();
        }
      }

      let lastSection = 0;
      let lastSectionOffset = 0;
      let hiddenOffset = 0;
      let questionData: DataCell[] =
        flattenData(
          polyModelModel?.map((field, i) => {
            // Custom render for section
            if (field.type === "section") {
              lastSectionOffset = i;
              return {
                cellKey: i + 1,
                component: (
                  <div className="!pt-8 flex justify-between c-table_title">
                    <Title
                      title={`(${String.fromCharCode(
                        "A".charCodeAt(0) + lastSection++
                      )}) ${field.caption?.en}`}
                      zhTitle={field.caption?.["zh-Hant"]}
                      bar
                    />
                    <ProductLegend className="pl-2" />
                  </div>
                ),
                header: true,
              };
            }

            if (field.hidden) {
              hiddenOffset += 1;
            }

            // Normal Render
            const questionIndex = i - lastSectionOffset - hiddenOffset;
            return {
              className: clsx([
                "clean w-[100px]",
                field.hidden && "row-hidden",
              ]),
              textClassName: "!text-left font-semibold",
              value: <Question index={`${questionIndex}.`} field={field} />,
              cellKey: i,
              groupedValue: field.children?.map((s, j) => ({
                className: "clean",
                textClassName: "!text-left sublevel",
                cellKey: i,
                value: (
                  <Question index={`${questionIndex}.${j + 1}.`} field={s} />
                ),
              })),
            };
          })
        ) ?? [];

      if (
        _.isEmpty(data) ||
        _.isEmpty(questionData) ||
        _.isEmpty(equipmentData)
      ) {
        return [];
      }

      // Have columns per view
      if (columnsPerPage) {
        const numberOfQuestions = data?.length ?? 0;
        const numberOfRound = Math.ceil(
          (replies?.length ?? 0) / columnsPerPage
        );

        // Slice data up
        const slicedEquipmentData = _.chunk(
          equipmentData ?? [],
          columnsPerPage
        );

        let chunkedData: DataCell[][][] = _.range(numberOfRound).map(() => []);
        data.forEach((rowData) => {
          _.range(numberOfRound).forEach((i) => {
            const slice = rowData.slice(
              i * columnsPerPage,
              (i + 1) * columnsPerPage
            );
            chunkedData[i] = [...chunkedData[i], slice];
          });
        });

        const finalData = chunkedData.flatMap((s, i) =>
          s.map((k) =>
            k.map((o) => {
              return {
                ...o,
                cellKey: o.cellKey + i * numberOfQuestions,
              };
            })
          )
        );

        // Break by sections
        const sections: [number, number][] = [];
        let finalQuestionData: DataCell[] = [];

        (questionData ?? []).forEach((s, i) => {
          if (s.header) {
            // Find last section
            if (sections.length > 0) {
              sections[sections.length - 1][1] = i;
            }
            sections.push([i, (questionData ?? []).length]);
          }
        });

        sections.forEach(([start, end]) => {
          const header = questionData[start];
          const subQuestions = questionData.slice(start + 1, end);

          // push data
          _.range(numberOfRound).forEach((i) => {
            const headerData = [
              { value: "-", className: "invisible" },
              ...slicedEquipmentData[i],
            ] as DataCell[];
            finalQuestionData = [
              ...finalQuestionData,
              {
                ...header,
                cellKey: header.cellKey + i * numberOfQuestions,
                header: false,
                renderHeaderData: [headerData, i * columnsPerPage],
                span: columnsPerPage + 1,
                className: i > 0 ? "sub-row-header" : undefined,
              },
              ...subQuestions.map((k, j) => ({
                ...k,
                cellKey: k.cellKey + i * numberOfQuestions,
                className: clsx([
                  j === subQuestions.length - 1 ? "last-ans-row" : undefined,
                  k.className,
                ]),
              })),
            ];
          });
        });

        // Assign data
        questionData = finalQuestionData;
        data = finalData;
      }

      return renderTable((props) => [
        <Table
          key={JSON.stringify(props)}
          tableRef={ref}
          row={questionData}
          data={data}
          col={[
            { value: "-", className: "invisible" },
            ...((equipmentData as DataCell[]) ?? []),
          ]}
          noBaseHeader
          useGrouped
          fixed
          {...props}
        />,
      ]);
    },
    [renderTable, polyModel?.model, replies]
  );

  return handler;
};

const Question = ({ index, field }: { index: string; field: any }) => {
  const [, isLang, isBi] = useLang();

  if (!field || field.hidden) {
    return <></>;
  }

  const inlineStyle = "text-[10px] font-light inline-block pl-1";

  const prefix = (
    <>
      {field.type !== "group" && field.cms_options?.suffix && (
        <small className={inlineStyle}>({field.cms_options.suffix})</small>
      )}
      {field.type !== "group" && field.cms_options?.prefix && (
        <small className={inlineStyle}>({field.cms_options.prefix})</small>
      )}
    </>
  );

  return (
    <React.Fragment key="spread">
      {isLang("en") && (
        <div className="flex">
          <span className="pr-[2px] whitespace-nowrap">{index}</span>
          <span>
            {field.caption?.en}
            {prefix}
            {field.type !== "group" && field.range && (
              <small className={inlineStyle}>
                (Range: {field.range.min}-{field.range.max})
              </small>
            )}
          </span>
        </div>
      )}
      {isLang("zh-Hant") && (
        <div className={clsx(["flex", isBi && "text-[10px]"])}>
          <span
            className={clsx([
              "pr-[2px] whitespace-nowrap",
              isBi && "invisible",
            ])}
          >
            {index}
          </span>
          <span>
            {field.caption?.["zh-Hant"]}
            {prefix}
            {field.type !== "group" && field.range && (
              <small className="text-[10px] font-light inline-block">
                (範圍: {field.range.min}-{field.range.max})
              </small>
            )}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

export default useProductionQuestionDetail;
