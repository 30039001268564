import React from "react";
import qs from "query-string";
import OverviewAdhocPage from "./adhoc";
import OverviewTaskPage from "./task";
import useForceReload from "app/RootNavigation/useForceReload";

const OverviewPage = () => {
  const url = qs.parse(window.location.search);

  // Clear api cache on load this page
  const ready = useForceReload(true);

  if (!ready) {
    return <></>;
  }

  if (url.adhoc === "1") {
    return <OverviewAdhocPage />;
  }

  return <OverviewTaskPage />;
};

export default OverviewPage;
