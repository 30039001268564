import IconCheck from "components/Icon/IconCheck";
import _ from "lodash";
import { isArray, isString, isNumber, isBoolean } from "lodash-es";
import React, { useContext } from "react";
import useLang from "utils/hooks/useLang";
import DataDisplayContext from "./DataDisplayContext";

const DataDisplay = ({
  value: inputValue,
  type,
  field,
}: {
  value: any;
  type?: string;
  field: any;
}) => {
  const [lang, , isBi] = useLang();
  const { values } = useContext(DataDisplayContext);

  // Use multilingual value
  let value = inputValue;
  if (_.isPlainObject(inputValue)) {
    if (inputValue[lang]) {
      value = inputValue[lang];
    } else if (inputValue["en"]) {
      value = inputValue["en"]; // fallback
    } else if (inputValue["zh-Hant"]) {
      value = inputValue["zh-Hant"]; // fallback
    }
  }

  if (field.hidden) {
    return <></>;
  }

  if (type === "image") {
    // Use refer mode
    let label = "";
    if (isArray(value) && value.length >= 1) {
      if (isBi) {
        label = "ref. to 參考 ";
      } else if (lang === "zh-Hant") {
        label = "參考 ";
      } else {
        label = "ref. to ";
      }
    } else {
      label = "-";
    }
    return (
      <>
        {label}
        {isArray(value) ? value.map((s) => values[s] || s).join(", ") : ""}
      </>
    );
  }

  if (type === "select" || type === "select_default") {
    // Prepare options for multilingual
    let options: any;
    if (type === "select_default") {
      options = ["normal", "abnormal", "repaired"];
    } else {
      if (_.isArray(field.options)) {
        options = field.options;
      } else if (_.isPlainObject(field.options)) {
        if (field.options[lang]) {
          options = field.options[lang];
        } else if (field.options["en"]) {
          options = field.options["en"];
        }
      } else {
        options = [];
      }
    }

    if (!options) {
      return <>-</>;
    }

    const optionKey = _.values(options)
      .map((s) => String(s).toLowerCase())
      .sort()
      .join("-");

    if (!_.isUndefined(value) && !_.isNull(value)) {
      // Special mapped option
      if (
        optionKey === "abnormal-normal-repaired" ||
        optionKey === "abnormal-normal" ||
        optionKey === "abnormal-repaired" ||
        optionKey === "normal-repaired" ||
        optionKey === "已修復-待處理-正常"
      ) {
        const mappedValue = String(options[value]).toLowerCase();
        if (mappedValue === "abnormal" || mappedValue === "待處理") {
          return <>X</>;
        }
        if (mappedValue === "normal" || mappedValue === "正常") {
          return <IconCheck />;
        }
        if (mappedValue === "repaired" || mappedValue === "已修復") {
          return <>O</>;
        }
        // Prevent undefined or null going through
        if (mappedValue === "undefined" || mappedValue === "null") {
          return <>-</>;
        }
        return <>{mappedValue}</>;
      }

      // Normal option
      return <>{options[value] ?? "-"} </>;
    }
    return <>-</>;
  }

  if (isString(value) || isNumber(value)) {
    if (value === "") {
      return <>-</>;
    }
    return <>{value}</>;
  }

  if (isArray(value)) {
    if (value.length === 0) {
      return <>-</>;
    }
    return (
      <>
        {value.map((s, i) => (
          <span key={JSON.stringify({ s, i })}>
            {i > 0 && ", "}
            <DataDisplay value={s} type={type} field={field} />
          </span>
        ))}
      </>
    );
  }

  if (isBoolean(value)) {
    return <>{value ? <IconCheck /> : "X"}</>;
  }

  if (!value) {
    return <>-</>;
  }

  return <>{JSON.stringify(value)}</>;
};

export default DataDisplay;
