import useAdhocDetail from "containers/useAdhocDetail";
import useModuleDetail from "containers/useModuleDetail";
import useTaskDetail from "containers/useTaskDetail";
import React from "react";
import { captionTranslate } from "utils/transform/multilang";

import stripRichText from "utils/transform/stripRichText";

const FooterDetail = ({
  pageNumbers,
  isAdhoc,
}: {
  pageNumbers: number | string;
  isAdhoc?: boolean;
}) => {
  const { data: taskData } = useTaskDetail(!isAdhoc);
  const { data: adhocData } = useAdhocDetail(!!isAdhoc);
  const itemData = isAdhoc ? adhocData : taskData;

  const { data } = useModuleDetail("ies_company", itemData?.value.ies_company);

  const address = stripRichText(
    [
      captionTranslate(data?.value?.address?.address_line_1, undefined, ""),
      captionTranslate(data?.value?.address?.address_line_2, undefined, ""),
      captionTranslate(data?.value?.address?.address_line_3, undefined, ""),
    ]
      .filter((s) => !!s)
      .join(" ")
  );

  return (
    <div>
      <img
        src="/images/footer-2.png"
        className="absolute bottom-0 left-0 right-0 opacity-40 h-[250px] w-full object-cover pointer-events-none"
        alt="footer"
      />
      <hr className="border-[#6E95C2] opacity-40 w-[700px] relative z-10" />
      <div className="flex items-center h-[40px] relative z-10">
        <div className="text-[10px] mr-5 pl-[14px] w-[30px]">{pageNumbers}</div>
        <div className="w-[60x] mr-2">
          <img src="/images/logo.png" alt="logo" className="w-auto h-[27px]" />
        </div>
        <div className="text-black mb-[12px]">
          <b className="text-[10px] leading-tight m-0 text-[#01328A]">
            {stripRichText(captionTranslate(data?.value?.name))}
          </b>
          <p className="text-[9px] leading-tight m-0">
            {stripRichText(data?.value?.email)}
          </p>
        </div>
        <div className="h-[22px] border-l border-[#C1C1C1] opacity-40 mx-[18px]" />
        <div className="text-black w-[230px] text-[9px] leading-[1.6] mb-[2px]">
          {address}
        </div>
        <div className="h-[22px] border-l border-[#C1C1C1] opacity-40 mx-[18px]" />
        <div className="text-black text-[9px]">
          <div className="flex">
            <b className="pr-1">Tel:</b>
            <p>{stripRichText(data?.value?.phone)}</p>
          </div>
          <div className="flex mt-[1px]">
            <b className="pr-1">Fax:</b>
            <p>{stripRichText(data?.value?.fax)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterDetail;
