/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { memo, useMemo, useState } from "react";

import { Route, Switch } from "react-router-dom";

import routes from "config/routes";
import OverviewPage from "pages/OverviewPage";
import CoverPage from "pages/CoverPage";
import AppendixPage from "pages/AppendixPage";
import SummaryPage from "pages/SummaryPage";
import ProductPage from "pages/ProductPage";
import ReadyContext from "./ReadyContext";
import LoadingReadyIndicator from "./LoadingReadyIndicator";
import CoverAdhocPage from "pages/CoverPage/adhoc";
import SummaryAdhocPage from "pages/SummaryPage/adhoc";
import AppendixAdhocPage from "pages/AppendixPage/adhoc";
import ProductAdhocPage from "pages/ProductPage/adhoc";
import useForceReload from "./useForceReload";
import usePreview from "utils/hooks/usePreview";

require("moment/locale/zh-cn");
require("moment/locale/zh-hk");

function RootNavigation() {
  const [ready, setReady] = useState(false);

  const isPreview = usePreview();
  const needReload = useMemo(() => {
    if (!isPreview) {
      return false;
    }
    const lastVisitedPage = window.sessionStorage.getItem("last_visited");
    window.sessionStorage.setItem("last_visited", window.location.href);
    if (lastVisitedPage === window.location.href) {
      // Same page, need reload
      return true;
    }
    return false;
  }, [isPreview]);

  const reloadReady = useForceReload(needReload);
  if (!reloadReady) {
    return <></>;
  }

  return (
    <>
      <LoadingReadyIndicator setReady={setReady} />
      <ReadyContext.Provider value={ready}>
        <Switch>
          <Route component={CoverPage} exact path={routes.cover()} />
          <Route component={OverviewPage} exact path={routes.overview()} />
          <Route component={AppendixPage} exact path={routes.appendix()} />
          <Route component={SummaryPage} exact path={routes.summary()} />
          <Route component={ProductPage} path={routes.product("")} />
          <Route component={CoverAdhocPage} exact path={routes.coverAdhoc()} />
          <Route
            component={SummaryAdhocPage}
            exact
            path={routes.summaryAdhoc()}
          />
          <Route
            component={AppendixAdhocPage}
            exact
            path={routes.appendixAdhoc()}
          />
          <Route
            component={ProductAdhocPage}
            exact
            path={routes.productAdhoc()}
          />
        </Switch>
      </ReadyContext.Provider>
    </>
  );
}

export default memo(RootNavigation);
