/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useCallback, useState } from "react";

import RootNavigation from "app/RootNavigation";

import { QueryClient, QueryClientProvider } from "react-query";
import { Router } from "react-router-dom";

import { GlobalStyle } from "styles/global-styles";
import history from "utils/service/history";
import CoverResizer from "components/CoverResizer";
import usePreview from "utils/hooks/usePreview";
import DecryptedToken from "containers/auth/DecryptedToken";
import { DataDisplayContextProvider } from "components/ProductionQuestionDetail/DataDisplay/DataDisplayContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 15000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  const isPreview = usePreview();
  const [values, setValues] = useState<Record<string, string>>({});

  const setValue = useCallback((key: string, value: string) => {
    setValues((s) => ({
      ...s,
      [key]: value,
    }));
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <Router history={history}>
        <DecryptedToken>
          <DataDisplayContextProvider value={{ values, setValue }}>
            <RootNavigation />
          </DataDisplayContextProvider>
        </DecryptedToken>
      </Router>
      {isPreview && <CoverResizer />}
    </QueryClientProvider>
  );
}
