/* eslint-disable camelcase */
/**
 *
 * ImageFromServer
 *
 * {{domain}}/upload/{{preview}}/{{module_name}}/{{id}}/{{size}}/{{filename}}
 * preview: optional, if in the staging site, please add “preview”
 *
 * module_name: required, the module name, eg. course, teacher_stats
 *
 * size: optional, the format are
 * 100p50 W:100 x H:50 proportional
 * 100c50 W:100 x H:50 cropped
 *
 */
import React, { CSSProperties, memo } from "react";

import _ from "lodash";

import config from "config";
import { ServerImage } from "types/module";

interface ServerImageInput
  extends Pick<ServerImage, "id" | "width" | "height" | "module_name"> {
  uid?: string;
  filename?: string;
}

interface Props {
  image: ServerImageInput | null | undefined;
  alt?: string;
  type: "cms" | "preview" | undefined;
  width: number | "auto";
  height: number | "auto";
  widthDisplay?: number;
  heightDisplay?: number;
  getParam?: string;
  style?: CSSProperties;
  encryptedToken?: string;
  cover?: boolean;
  uuid?: boolean;
  className?: string;
}

export const imageToUrl = (
  image: ServerImageInput,
  encryptedToken: string,
  {
    uuid = true,
    withToken = true,
    width,
    height,
    type,
  }: {
    uuid: boolean;
    withToken: boolean;
    width?: number | "auto";
    height?: number | "auto";
    type: "cms" | "preview" | undefined;
  }
) => {
  const { filename, module_name: moduleName, id, uid } = image;

  let resize = "";
  if (width !== "auto" && height !== "auto") {
    if (width && height) {
      resize = `${width}p${height}/`;
    } else if (width) {
      resize = `${width}p0/`;
    } else if (height) {
      resize = `0p${height}/`;
    }
  }

  let getStr = "";
  if (withToken && uuid) {
    getStr = `?uuid=true&${encryptedToken}`;
  } else if (uuid) {
    getStr = "?uuid=true";
  } else if (withToken) {
    getStr = `?${encryptedToken}`;
  }

  let typeStr = "";
  if (type) typeStr = `${type}/`;

  // Prepare url to server format
  const url = `${
    config.apiDomain
  }/upload/${typeStr}${moduleName}/${id}/${resize}${uuid ? uid : filename}`;

  return {
    url: encodeURI(url) + getStr,
    getStr,
  };
};

function Image({
  image,
  alt = "",
  type,
  width,
  height,
  widthDisplay,
  heightDisplay,
  getParam = "",
  style,
  encryptedToken,
  cover = false,
  uuid = false,
  className,
}: Props) {
  const isInvalid =
    !_.has(image, "id") ||
    !_.has(image, "width") ||
    !_.has(image, "module_name") ||
    !_.has(image, "height") ||
    (!uuid && !_.has(image, "filename")) ||
    (uuid && !_.has(image, "uid")) ||
    !config.apiDomain;

  // Check if image has all props
  if (isInvalid || !image) {
    return <></>;
  }

  const { url } = imageToUrl(image, encryptedToken ?? "", {
    uuid,
    withToken: !!encryptedToken,
    width,
    height,
    type,
  });

  return (
    <img
      className={className}
      alt={alt}
      height={heightDisplay || height}
      src={url + getParam}
      style={{
        ...(style ? style : {}),
        ...(cover ? { objectFit: "cover", objectPosition: "50% 50%" } : {}),
      }}
      width={widthDisplay || width}
    />
  );
}

export default memo(Image);
