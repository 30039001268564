import React from "react";

const RemainingHeightContext = React.createContext<{
  offsetHeight: number | undefined;
  wrapperHeight: number | undefined;
}>({ offsetHeight: undefined, wrapperHeight: undefined });

export const RemainingHeightContextProvider = RemainingHeightContext.Provider;

export default RemainingHeightContext;
