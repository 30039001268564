import { isString } from "lodash-es";
import qs from "query-string";

const redirectToLoginPage = async (_1: any = null, _2: any = null) => {
  console.error("Unauthorized");
};

// Set authentication token header to apisauce instance
export const setTokenHeader = async (optional = false, apiPath?: string) => {
  const token = getToken();

  if (!token) {
    if (!optional) {
      redirectToLoginPage();
    }
    return false;
  }

  return token;
};

export const getToken = () => {
  const url = qs.parse(window.location.search);
  if (url.t === "1") {
    const localToken = window.localStorage.getItem("s");
    if (localToken) {
      return localToken;
    }
  } else {
    if (isString(url.t)) {
      return url.t;
    }
  }

  if (process.env.REACT_APP_API_TOKEN) {
    return process.env.REACT_APP_API_TOKEN;
  }

  return undefined;
};
