import React from "react";

const HeaderPatten = () => {
  return (
    <svg
      width="148"
      height="122"
      viewBox="0 0 148 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.157 50.6582V78.4812L98.831 105.434H100.526V79.0292L72.157 50.6582Z"
        fill="#EC472A"
      />
      <path
        d="M122.182 15.0011H147.082L122.182 -9.89893V15.0011Z"
        fill="#D0001B"
      />
      <path
        d="M122.696 22.4292V44.4292H144.696L122.696 22.4292Z"
        fill="#006EAF"
      />
      <path
        d="M105.121 61.3462L82.8311 39.0562V61.3462H105.121Z"
        fill="#0B777D"
      />
      <path
        d="M122.733 44.4341L68.459 -9.87891H41.208L68.383 17.2961H68.419L95.558 44.4351L122.733 44.4341Z"
        fill="#6B0C15"
      />
      <path
        d="M52.822 31.6669H83.181L41.545 -9.86914H11.187L52.822 31.6669Z"
        fill="#E71E18"
      />
      <path
        d="M68.2629 -9.93799H122.638L122.565 44.491L68.2629 -9.93799Z"
        fill="#B40920"
      />
      <path
        d="M53.3601 1.84521V31.6652H83.1801L53.3601 1.84521Z"
        fill="#061127"
      />
      <path
        d="M34.906 31.5508H13.23L42.957 61.2048H64.633L34.906 31.5508Z"
        fill="#131C4B"
      />
      <path d="M82.831 79.9348V31.5508H34.522L82.831 79.9348Z" fill="#00212B" />
      <path
        d="M94.9729 121.582L83.9299 110.539V121.582H94.9729Z"
        fill="#FFBD75"
      />
      <path d="M105.341 61.346V45.646L120.584 61.346H105.341Z" fill="#38B4AD" />
      <path
        d="M59.3939 85.214H59.3619L35.4179 61.27H11.4419L35.4179 85.246H35.4499L59.2039 109.001H83.1799L59.3939 85.214Z"
        fill="#E82D36"
      />
      <path
        d="M91.4829 -40.562L122.101 -9.94402L147.079 15.001V-9.97801H147.046L116.461 -40.562H91.4829Z"
        fill="#E71E18"
      />
      <path
        d="M52.7849 31.7271L83.0849 62.027V31.7271H52.7849Z"
        fill="#6B0C15"
      />
      <path
        d="M107.729 2.52393H105.854V4.39893H107.729V2.52393Z"
        fill="#E71E18"
      />
      <path
        d="M90.145 -9.93799H122.639L122.595 22.589L90.145 -9.93799Z"
        fill="#DF212E"
      />
      <path
        d="M35.3999 61.271L83.1799 108.941V61.366L35.3999 61.271Z"
        fill="#B40920"
      />
      <path
        d="M82.8311 61.3438L100.765 79.2817H123.309L105.375 61.3438H82.8311Z"
        fill="#38B4AD"
      />
      <path
        d="M-15.0039 -36.8022V31.6668H53.3601L-15.0039 -36.8022Z"
        fill="#003A8F"
      />
      <path
        d="M-15.01 22.4351L23.742 61.2141H43.199L23.799 41.8141H23.773L4.39299 22.4351H-15.01Z"
        fill="#003A8F"
      />
      <path
        d="M23.88 61.257H-14.943L-15.01 22.436L23.88 61.257Z"
        fill="#003345"
      />
      <path
        d="M11.6831 83.5562L33.8231 105.601H11.6831V83.5562Z"
        fill="#21ABC8"
      />
      <path
        d="M-10.4419 61.3149V83.455H-10.4119L11.6981 105.565V83.4249H11.6681L-10.4419 61.3149Z"
        fill="#129795"
      />
      <path
        d="M11.512 61.2729H-10.665L11.327 83.5559H33.791L11.512 61.2729Z"
        fill="#E71E18"
      />
      <path
        d="M33.8911 96.1992V105.599H43.2911L33.8911 96.1992Z"
        fill="#FFB100"
      />
      <path d="M34.521 52.8418V31.5508H13.23L34.521 52.8418Z" fill="#061127" />
      <path
        d="M33.8231 105.601L11.6831 83.5562H33.8231V105.601Z"
        fill="#F8810A"
      />
    </svg>
  );
};

export default HeaderPatten;
