/**
 *
 * ErrorMessage
 *
 */

import React, { CSSProperties, memo } from "react";

import clsx from "clsx";
import { isArray, isPlainObject, isString } from "lodash-es";

type Props = {
  style?: CSSProperties;
  className?: string;
  error?:
    | {
        error?: boolean;
        errorCode?: string;
        errorMessage?: string;
      }
    | unknown
    | string
    | null;
};

const styles = {
  errorMessageWrapper: "text-red body3 my-5",
};

const safeJsonParse = (value: any, fallback: any) => {
  try {
    return JSON.parse(value);
  } catch (e) {}
  return fallback;
};

function ErrorMessage({ style, className, error }: Props) {
  if (!error) {
    return <></>;
  }
  let message: string | undefined;
  let code: string | undefined;

  const json = typeof error === "string" ? safeJsonParse(error, error) : false;

  if (isPlainObject(json)) {
    message = (json as any).errorMessage;
    code = (json as any).errorCode;
  } else if (typeof error === "string") {
    message = error;
    code = error;
  } else if (isPlainObject(error)) {
    message = (error as any).errorMessage;
    code = (error as any).errorCode;
  }

  if (!message) {
    return <></>;
  }

  if (isString(message)) {
    return (
      <div
        className={clsx([className, styles.errorMessageWrapper])}
        style={style}
      >
        {message}
      </div>
    );
  }

  if (isPlainObject(message) && code === "form-error") {
    return (
      <div
        className={clsx([className, styles.errorMessageWrapper])}
        style={style}
      >
        {Object.values(message)?.map((formError: any) => {
          if (isArray(formError)) {
            return formError.map((e) => (
              <p style={{ marginTop: 10 }} key={e}>
                {e}
              </p>
            ));
          }
          return <></>;
        })}
      </div>
    );
  }

  return (
    <div
      className={clsx([className, styles.errorMessageWrapper])}
      style={style}
    >
      出問題了，請重試
    </div>
  );
}

export default memo(ErrorMessage);
