import clsx from "clsx";
import _ from "lodash";
import React, { ReactNode } from "react";
import useLang from "utils/hooks/useLang";

type Props = {
  title: string;
  zhTitle?: string;
  value: ReactNode;
  className?: string;
  children?: ReactNode;
};

function TextTuple({ title, zhTitle, value, className, children }: Props) {
  const [, isLang] = useLang();

  var isEmpty = false;
  if (_.isString(value)) {
    isEmpty = value.indexOf("<h6>") === 0;
  }

  return (
    <div className={clsx(["flex items-center", className])}>
      <div className="text-[#01328A] mr-3 font-bold  min-w-[170px] max-w-[170px] w-[170px] text-xs">
        {isLang("en") && <span className="mr-2 inline-block">{title}</span>}
        {isLang("zh-Hant") &&
          (zhTitle ? <span className="inline-block">{zhTitle}</span> : "")}
      </div>
      {children ? (
        children
      ) : (
        <div
          className="bg-[#F0F0F0] px-3 py-1 w-full text-xs"
          style={{ wordBreak: "break-word" }}
        >
          {isEmpty ? "-" : value || "-"}
        </div>
      )}
    </div>
  );
}

export default TextTuple;
