import clsx from "clsx";
import React, { memo, ReactNode, useEffect, useRef } from "react";

type Props = {
  children: ReactNode;
  setHeight: (height: number, index: number) => void;
  invisible: boolean;
  onInvisible?: (index: number) => void;
  index: number;
  disabled?: boolean;
  hidden?: boolean;
  id?: string;
  className?: string;
};

const SizedTableRow = ({
  children,
  setHeight,
  invisible,
  onInvisible,
  index,
  disabled,
  hidden,
  id,
  className,
}: Props) => {
  const ref = useRef<HTMLTableSectionElement>(null);

  useEffect(() => {
    if (invisible) {
      onInvisible?.(index);
    }
  }, [onInvisible, invisible, index]);

  useEffect(() => {
    if (!ref.current || disabled) {
      return;
    }

    let calculated = 0;
    const interval = setInterval(() => {
      const rect = ref.current?.getBoundingClientRect();
      if (rect?.height) {
        setHeight(rect.height, index);
        calculated += 1;
      }
      if (calculated > 5) {
        clearInterval(interval);
      }
    }, 400);

    return () => {
      clearInterval(interval);
    };
  }, [setHeight, index, disabled]);

  return (
    <tbody
      id={id}
      ref={ref}
      className={clsx([
        `i_${index}`,
        invisible && "invisible",
        hidden && "hidden",
        className,
      ])}
    >
      {children}
    </tbody>
  );
};

export default memo(SizedTableRow);
